import { JobAutoFormCosmicMetadata } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';

import { parseToHtml } from '~/utils/html';

import { titleHeading } from '../B2BContactForm/B2BContactForm';
import FormikWrapper from './FormikWrapper';

type Props = {
  data: JobAutoFormCosmicMetadata;
};

export const JobAutoContactForm = ({ data }: Props) => {
  const titleClassName = titleHeading[data.header_size?.key] || titleHeading.h2;
  const TitleEl =
    (data.header_size?.key as keyof JSX.IntrinsicElements) || 'h2';

  return (
    <div className="container">
      <div
        id="jobauto-contact-form"
        className="grid grid-cols-1 gap-6 md:grid-cols-2"
      >
        <div>
          <TitleEl className={cn(titleClassName, 'mb-14')}>
            {parseToHtml(data.title)}
          </TitleEl>
          <p className="body-16-light">{parseToHtml(data.description)}</p>
        </div>
        <div>
          <FormikWrapper cosmicData={data.form_type} />
        </div>
      </div>
    </div>
  );
};

export default JobAutoContactForm;
