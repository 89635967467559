import { FormikInput } from '@finn/auto-ui/components/B2BContactForm/Form';
import { Button } from '@finn/design-system/atoms/button';
import { JobAutoFormTypeCosmicData, UIBaseCosmicObject } from '@finn/ui-cosmic';
import { FormikProps } from 'formik';
import React, { useState } from 'react';

import FormikCheckbox from '~/components/FormElements/Checkbox/FormikCheckbox';
import TextArea from '~/components/FormElements/TextArea';
import { getInputPlaceholder } from '~/utils/cosmic';
import { parseToHtml } from '~/utils/html';

import { FormValues } from './data';

type JobAutoType = UIBaseCosmicObject<JobAutoFormTypeCosmicData>;

interface IProps {
  formik: FormikProps<FormValues>;
  loading: boolean;
  cosmicData: JobAutoType;
}
const Form: React.FunctionComponent<IProps> = ({
  formik,
  loading,
  cosmicData,
}) => {
  const [showGdpr, setShowGdpr] = useState(false);
  const GDPR = cosmicData.metadata.input_fields.gdpr;

  const handleGDPRToggle = (e) => {
    e.preventDefault();
    setShowGdpr(!showGdpr);
  };

  return (
    <form>
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
        <div className="sm:col-start-1 sm:col-end-3">
          <FormikInput
            name="company"
            label={getInputPlaceholder(cosmicData, 'company')}
          />
        </div>
        <div>
          <FormikInput
            name="firstName"
            label={getInputPlaceholder(cosmicData, 'first_name')}
          />
        </div>
        <div>
          <FormikInput
            name="lastName"
            label={getInputPlaceholder(cosmicData, 'last_name')}
          />
        </div>
        <div>
          <FormikInput
            name="email"
            type="email"
            label={getInputPlaceholder(cosmicData, 'email_address')}
          />
        </div>
        <div>
          <FormikInput
            name="phoneNumber"
            label={getInputPlaceholder(cosmicData, 'phone_number')}
            placeholder={cosmicData.metadata.input_fields.phone_number_format}
          />
        </div>
        <div>
          <FormikInput
            name="numberOfEmployees"
            label={getInputPlaceholder(cosmicData, 'number_of_employees')}
            type="number"
          />
        </div>
        <div>
          <FormikInput
            name="fleetSize"
            label={getInputPlaceholder(cosmicData, 'fleet_size')}
            type="number"
          />
        </div>

        <div className="sm:col-start-1 sm:col-end-3">
          <TextArea
            name="message"
            placeholder={getInputPlaceholder(cosmicData, 'message')}
          />
        </div>
        <div className="-mt-2 sm:col-start-1 sm:col-end-3">
          <FormikCheckbox name="gdpr" label={GDPR.title} />
          <div className="[&_p]:body-14-regular ml-6 [&_p]:pt-1">
            <Button variant="action" size="md" onClick={handleGDPRToggle}>
              {showGdpr ? GDPR.hide_details : GDPR.show_details}
            </Button>
            {showGdpr && (
              <>
                <br />
                <br />
                <p className="body-14-regular">{parseToHtml(GDPR.gdpr_note)}</p>
              </>
            )}
          </div>
        </div>
        <div className="mb-4 sm:col-start-1 sm:col-end-3 sm:mb-6">
          <Button onClick={() => formik.handleSubmit()} loading={loading}>
            {cosmicData.metadata.submit_button.button_label}
          </Button>
        </div>
      </div>
    </form>
  );
};
export default Form;
